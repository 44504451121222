import React from 'react';
// import RotatingBox from 'components/RotatingBox/RotatingBox';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { getLangFile } from 'utils/lang';
import threedad from 'assets/images/threedad.svg';
import threedadTitle from 'assets/images/threedad-title.svg';
// import AdSwitcher from 'components/AdSwitcher/AdSwitcher';
import ProductFeatures from './ProductFeatures';
import './ProductSection.scss';

const ProductSection = () => {
  const lang = getLangFile();
  const t = lang.productSection;
  return (
    <SectionWrapper title={lang.menu.product}>
      <div className="Product-3dad">
        <div className="Product-3dad-left">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
            <img src={threedad} />
            <img className="Product-3dad-title" src={threedadTitle} />
          </div>
          <p className="Product-3dad-right-subtitle">{t.subTitle}</p>
          {/* <RotatingBox /> */}
          {/* <AdSwitcher toggleBirds={toggleBirds} /> */}
        </div>
        <div className="Product-3dad-right">
          <p dangerouslySetInnerHTML={{ __html: t.desc }} />
        </div>
      </div>
      <ProductFeatures t={lang} />
    </SectionWrapper>
  );
};

export default ProductSection;
