import React from 'react';
import Media from 'react-media';

import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { getLangFile } from 'utils/lang';
import one from 'assets/images/photo/1.jpg';
import oneSm from 'assets/images/photo/1-sm.png';
import two from 'assets/images/photo/2.jpg';
import twoSm from 'assets/images/photo/2-sm.png';
import threeSm from 'assets/images/photo/3-sm.png';
import fourSm from 'assets/images/photo/4-sm.png';
import three from 'assets/images/photo/3.jpg';
import four from 'assets/images/photo/4.png';

import './StudioSection.scss';

const StudioSection = () => {
  const t = getLangFile();
  const companyInfo = t.studioSection.companyInfo;
  const companyProjects = t.studioSection.companyProjects;

  return (
    <SectionWrapper title={t.menu.studio}>
      <h4>{t.studioSection.title}</h4>
      <div className="StudioSection-infolist">
        {companyInfo.map(info => (
          <div key={info.title} className="StudioSection-info">
            <div className="StudioSection-info-title">{info.title}</div>
            <p className="StudioSection-info-text">{info.text}</p>
          </div>
        ))}
      </div>

      <Media query={{ maxWidth: 480 }}>
        {isSmaller => (
          <>
            <div className="StudioSection-photos">
              <div className="StudioSection-photo StudioSection-photo--one">
                <img src={isSmaller ? oneSm : one} />
              </div>
              <div className="StudioSection-photo StudioSection-photo--two">
                <img src={isSmaller ? twoSm : two} />
              </div>
            </div>
            <div className="StudioSection-photos">
              <div className="StudioSection-photo StudioSection-photo--three">
                <img src={isSmaller ? threeSm : three} />
              </div>
              <div className="StudioSection-photo StudioSection-photo--four">
                <img src={isSmaller ? fourSm : four} />
              </div>
            </div>
          </>
        )}
      </Media>

      <div className="StudioSection-projects">
        {companyProjects.map(project => (
          <div key={project.text} className="StudioSection-project">
            <h4>{project.number}</h4>
            <p>{project.text}</p>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
};

export default StudioSection;
