import React from 'react';
import { getLangFile } from 'utils/lang';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import NewsColumns from 'components/NewsColumns/NewsColumns';
import MoreButton from 'components/MoreButton/MoreButton';
import './NewsSection.scss';

const NewsSection = ({ data }) => {
  const t = getLangFile();
  return (
    <SectionWrapper title={t.menu.news}>
      <NewsColumns articles={data.allMarkdownRemark.edges} />
      <MoreButton link="/news" text={t.newsSection.previous} />
    </SectionWrapper>
  );
};

export default NewsSection;
