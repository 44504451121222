import React from 'react';
import { Element, scroller } from 'react-scroll';

// import Customers from 'components/Customers/Customers';
import Hero from 'components/Hero/Hero.js';
import ProductSection from 'components/ProductSection/ProductSection';
import Container from 'components/Container/Container';
import StudioSection from 'components/StudioSection/StudioSection';
import NewsSection from 'components/NewsSection/NewsSection';
import JobsSection from 'components/JobsSection/JobsSection';
import TweenWrapper from 'components/TweenWrapper/TweenWrapper';
import { menuKeys } from 'constants/menu';

import './IndexPage.scss';

class IndexPage extends React.Component {
  componentDidMount() {
    const { hash } = this.props;

    if (!!hash && hash !== '') {
      var elementName = hash.substr(1);

      scroller.scrollTo(elementName, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }
  render() {
    const { data } = this.props;
    return (
      <>
        <TweenWrapper
          duration={3000}
          triggerName={menuKeys[0]}
          toggleClass={['.IndexPage-home', 'IndexPage--triggered']}>
          <Element className="IndexPage-introduction" name={menuKeys[0]}>
            <Hero />
            {/* <Customers /> */}
          </Element>
        </TweenWrapper>

        <div className="IndexPage-background">
          <Container size="sm">
            <TweenWrapper
              duration={3000}
              triggerName={menuKeys[1]}
              toggleClass={['.IndexPage-product', 'IndexPage--triggered']}>
              <Element className="element IndexPage-product" name={menuKeys[1]}>
                <ProductSection />
              </Element>
            </TweenWrapper>

            <TweenWrapper
              duration={2000}
              triggerName={menuKeys[2]}
              toggleClass={['.IndexPage-studio', 'IndexPage--triggered']}>
              <Element className="element IndexPage-studio" name={menuKeys[2]}>
                <StudioSection />
              </Element>
            </TweenWrapper>

            <TweenWrapper
              duration={1400}
              triggerName={menuKeys[3]}
              toggleClass={['.IndexPage-news', 'IndexPage--triggered']}>
              <Element className="element IndexPage-news" name={menuKeys[3]}>
                <NewsSection data={data} />
              </Element>
            </TweenWrapper>

            <TweenWrapper
              duration={1000}
              triggerName={menuKeys[4]}
              toggleClass={['.IndexPage-jobs', 'IndexPage--triggered']}>
              <Element className="elementc IndexPage-jobs" name={menuKeys[4]}>
                <JobsSection />
              </Element>
            </TweenWrapper>
          </Container>
        </div>
      </>
    );
  }
}

export default IndexPage;
