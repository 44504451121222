import React from 'react';
import { features } from 'constants/features.js';

const ProductFeatures = ({ t }) => {
  return (
    <>
      <h5 className="Feature-title">{t.features.title}</h5>
      <div className="Product-features">
        {features(t.features).map((feature, index) => (
          <div key={index} className="Product-feature">
            <div className="Feature-image">
              <img src={feature.image} />
            </div>
            <div className="Feature-content">
              <div className="Feature-each-title">{feature.title}</div>
              <p>{feature.text}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductFeatures;
