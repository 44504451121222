export const jobs = [
  {
    title: '3DCGデザイナー(ゼネラリスト)',
    location: 'Shibuya, Tokyo',
    link: 'https://www.green-japan.com/job/81404', // placeholder
    content: `3D ADでは3Dテクノロジーを武器に、広告表現の限界に挑戦しています。
    <br /><br />
    あなたの作った広告を月間何千万人もの人が見ることになります。 <br />
    今はゲーム広告がメインですが、今後自動車やアパレルなどあらゆる分野に展開をしていきます。
    <br /><br />
    デジタル広告で最もリッチな、ユーザーに愛される広告を一緒に作りましょう。
    <br /><br />
    `,
  },
  {
    title: 'バックエンドエンジニア',
    location: 'Shibuya, Tokyo',
    link: 'https://levtech-direct.jp/job/956', //placeholder
    content: `弊社のプロダクト、3D ADの配信管理システムの構築と3D AD SDKの開発に関わってもらいます。
    <br /><br />

    【職務内容】<br />
    今回募集するのは、以下の開発をリードすることができるバックエンドエンジニアです。
    <br /><br />

    1. 広告入稿・配信を制御するアプリケーションの開発<br />
    - GraphQLを用いた広告管理APIの提供<br />
    - アプリ／サイト、広告枠、キャンペーン、広告、クリエイティブのCRUD<br />
    - 広告のクリエイティブ入稿<br />
    - 配信先、配信時間帯の制御<br />
    <br /><br />

    2. 広告配信パイプライン<br />
    ■ 広告配信の配信実績データの集計<br />
    ■ 機械学習を用いた各種KPIの予測ロジックのアルゴリズム作成及びそのチューニング<br />
    ■ 広告の予算消化や社内配信ルールに基づいた配信のバランシング<br />
    <br /><br />

    3. 広告配信サーバー<br />
    ■ gRPCによるAPIサーバーの構築<br />
    ■ 広告配信要求に対するレスポンスを50ms以内で返却するための各種チューニング<br />
    ■ 各種KPI予測を行うアルゴリズムの作成・反映`,
  },
  {
    title: '機械学習エンジニア/データサイエンティスト',
    location: 'Shibuya, Tokyo',
    link: 'https://levtech-direct.jp/job/957', //placeholder
    content: `機械学習システムのエンジニア及びデータサイエンティストとして、弊社の広告プラットフォームの開発・運用を行っていただきます。
    機械学習のテクノロジーを積極的に応用して、広告入札の最適化を行うシステムを提供します。
    <br /><br />

    【職務内容】<br />
    今回募集するのは、以下の開発をリードすることができる機械学習エンジニア及びデータサイエンティストです。
    <br /><br />

    【職務内容<br />
    1. 広告入札の最適化をはじめとした機械学習モデルの開発<br />
    ■ 入札価格や予算配分、入札戦略の最適化を目的とした機械学習アルゴリズムの開発<br />
    ■ 機械学習モデル精度向上のためのパラメータチューニングやデータ分析<br />
    <br /><br />

    2. 機械学習モデルの評価、監視、開発工程の改善を目的とした機械学習の運用基盤の開発<br />
    ■ GCPのAI PlatformやKubeflowを利用した機械学習システムの開発、運用<br />
    ■ Seldon, Tensowflow Serving, Pachydermなどを利用した機械学習システムの運用基盤の構築<br />
    ■ 10ms以内で応答する高速な推論システムの開発`,
  },
  {
    title: 'フロントエンドエンジニア',
    location: 'Shibuya, Tokyo',
    link: 'https://www.green-japan.com/job/80021',
    content: `
      弊社のプロダクト、3D ADの配信管理システムの構築と3D AD SDKの開発に関わってもらいます。
      <br /><br />

      【職務内容】<br />
      今回募集するのは、以下の開発をリードすることができるフロントエンドエンジニアです。
      <br /><br />

      1. 広告入稿・配信を制御するアプリケーションの開発<br />
      - アプリ／サイト、広告枠、キャンペーン、広告、クリエイティブのCRUD<br />
      - 広告のクリエイティブ入稿<br />
      - 配信先、配信時間帯の制御<br />
      - これらをWebUI/cli/botから制御出来るようにするインターフェースの提供
      <br /><br />

      2. 広告SDK<br />
      - 各種2D/3Dライブラリ(内製も含む)を利用した広告クリエイティブ表示エンジン<br />
      - デバイスから取得出来る情報とインテラクションの連携<br />
      - RTB、アドネットワークで配信するためのインターフェスの提供
    `,
  },
  {
    title: '営業責任者',
    location: 'Shibuya, Tokyo',
    link: 'https://www.green-japan.com/job/87528',
    content: `
      弊社のプロダクト、3D ADの営業責任者を募集しております。
      
      【職務内容】<br />
      - スマホアプリ企業へのプロダクトセールス<br />
      - ナショナルクライアントへのプロダクトセールス<br />
      - 営業組織の構築・採用<br />
      - アドテク企業とのアライアンス推進<br />
    `,
  },
];
