import React from 'react';
import './SectionWrapper.scss';

const SectionWrapper = ({ title, children }) => (
  <div className="SectionWrapper">
    <h4 className="SectionWrapper-title">{title}</h4>
    {children}
  </div>
);

export default SectionWrapper;
