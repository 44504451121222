import one from 'assets/images/features/1.svg';
import two from 'assets/images/features/2.svg';
import three from 'assets/images/features/3.svg';
import four from 'assets/images/features/4.svg';
import five from 'assets/images/features/5.svg';
import six from 'assets/images/features/6.svg';

export const features = t => [
  {
    title: t.one.title,
    image: one,
    text: t.one.desc,
  },
  {
    title: t.two.title,
    image: two,
    text: t.two.desc,
  },
  {
    title: t.three.title,
    image: three,
    text: t.three.desc,
  },
  {
    title: t.four.title,
    image: four,
    text: t.four.desc,
  },
  {
    title: t.five.title,
    image: five,
    text: t.five.desc,
  },
  {
    title: t.six.title,
    image: six,
    text: t.six.desc,
  },
];
