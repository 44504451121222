import React from 'react';
import Container from 'components/Container/Container';
import { getLangFile } from 'utils/lang';
import './Hero.scss';

const Hero = () => {
  const t = getLangFile().hero;
  return (
    <Container>
      <div className="Hero-wrapper">
        <div className="Hero">
          <h1 dangerouslySetInnerHTML={{ __html: t.title }} />
          <p>{t.desc}</p>
        </div>
      </div>
    </Container>
  );
};

export default Hero;
