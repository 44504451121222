import { graphql } from 'gatsby';
import React from 'react';
import IndexPage from 'components/IndexPage/IndexPage';
import 'styles/global.scss';

class Index extends React.Component {
  render() {
    const { data, location } = this.props;

    return <IndexPage data={data} hash={location.hash} />;
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 8
      sort: { fields: [frontmatter___createdAt], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            path
          }
        }
      }
    }
  }
`;
