import React from 'react';
import posed from 'react-pose';
import { getLangFile } from 'utils/lang';
import MoreButton from 'components/MoreButton/MoreButton';
import { jobs } from 'constants/jobs';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import arrowUp from 'assets/images/Icons/arrowUp.svg';

import './JobsSection.scss';

const Content = posed.div({
  closed: {
    height: 0,
  },
  open: {
    height: 'auto',
  },
});

const IconArrow = posed.div({
  up: {
    rotate: 0,
  },
  down: {
    rotate: 180,
  },
});

class JobsSection extends React.Component {
  state = { open: false };

  render() {
    const t = getLangFile();
    const { open } = this.state;
    return (
      <SectionWrapper title={t.menu.jobs}>
        {jobs.map(({ title, content, location, link }, i) => (
          <div key={title} className="Jobs-job">
            <div
              className="Jobs-title-area"
              onClick={() => this.setState({ open: open === i ? false : i })}>
              <div className="Jobs-title-wrapper">
                <h5 className="Jobs-title">{title}</h5>
                <span className="Jobs-place">{location}</span>
              </div>
              <IconArrow
                className="Jobs-icon-wrapper"
                style={{ transformOrigin: 'center' }}
                pose={open === i ? 'up' : 'down'}>
                <img src={arrowUp} />
              </IconArrow>
            </div>
            <Content className="Content" pose={open === i ? 'open' : 'closed'}>
              <div className="Content-wrapper">
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <div className="Job-content-moreButton">
                  <MoreButton text={t.jobsSection.moreDetails} href={link} />
                </div>
              </div>
            </Content>
          </div>
        ))}
      </SectionWrapper>
    );
  }
}

export default JobsSection;
