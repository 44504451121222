import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './NewsColumns.scss';

const NewsColumn = ({ articles, title, type, pathname }) => {
  return (
    <div className="NewsColumn">
      <h4>{title}</h4>

      {type === 'press' &&
        articles.map(article => {
          const { frontmatter } = article.node;
          if (pathname && pathname === frontmatter.path) return;
          return (
            <div key={frontmatter.date} className="ArticleThumbnail">
              <div className="ArticleThumbnail-date">{frontmatter.date}</div>
              <Link to={frontmatter.path} className="ArticleThumbnail-title">
                {frontmatter.title}
              </Link>
            </div>
          );
        })}
      {type === 'blog' &&
        articles.map(blog => {
          return (
            <div key={blog.date} className="ArticleThumbnail">
              <div className="ArticleThumbnail-date">{blog.date}</div>
              <a href={blog.link} target="_blank" className="ArticleThumbnail-title">
                {blog.title}
              </a>
            </div>
          );
        })}
    </div>
  );
};

NewsColumn.propTypes = {
  articles: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

NewsColumn.defaultProps = {
  pathname: null,
};

export default NewsColumn;
