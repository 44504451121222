import React from 'react';
import { getLangFile } from 'utils/lang';
import { blogs } from 'constants/blogs';
import NewsColumn from './NewsColumn';
import './NewsColumns.scss';

const NewsColumns = ({ articles }) => {
  const t = getLangFile().newsSection;
  return (
    <div className="NewsColumns">
      <NewsColumn title={t.press} articles={articles} type="press" />
      <NewsColumn title={t.blog} articles={blogs} type="blog" />
    </div>
  );
};

export default NewsColumns;
